/* eslint-disable import/no-cycle */
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { navigate } from '@reach/router'
import { Grid } from '@mui/material'
import Theme from '@refera/ui-core'
import { Button, Datagrid } from '@refera/ui-web'
import {
  FilterSearch as FilterIcon,
  SearchNormal1 as SearchIcon,
  DocumentUpload,
} from '@refera/ui-icons'

import { FINANCE_ROUTES, ROUTES } from '_/utils/constants'

import HeaderTitle from '_/components/header-title'

import useStyles from '../styles'
import { ReceiptsCsReferaColumns } from '../../manage-installments/utils/constants'
import { useDispatch, useSelector } from 'react-redux'
import { getListBudgetReceivables, postExportASAASReceivables } from '_/modules/finance/actions'

import { useToast } from '_/hooks/use-toast'
import { useDialog } from '_/hooks/use-dialog'
import useRolePermission from '_/hooks/use-role-permission'

import { userSelector } from '_/modules/authentication/selectors'
import { EmptyScreen } from '_/components/empty-screen'
import { getListBudgetsReceivablesSelector } from '_/modules/finance/selectors'
import { TABLE_SX, asaasConfirmationScreenTypes } from '../../utils/constants'
import { formatErrorMessage } from '../../utils/FormatErrorMessage'

import { FiltersAsaas } from '../components/filters-asaas'
import { formatAsaasFilters } from '../components/filters-asaas/utils'
import AsaasFloatingMenu from '../components/asaas-floating-menu'

import ReceiptsConfirmation from '../receipts-confirmation'
import { AsaasDownloadSpreadsheet } from '../components/asaas-download-spreadsheet'
import moment from 'moment'
import axios from 'axios'
import { API_URL } from '_/config/environment'
import { getToken } from '_/utils/token'
import humps from 'humps'
import { notEmptyPayload } from '_/utils/helpers'

const columnsToRemove = ['paymentDate', 'amountPaid']

const AsaasExport = () => {
  const styles = useStyles()
  const dispatch = useDispatch()

  const { isAdmin } = useRolePermission()

  const user = useSelector(userSelector)
  const receipts = useSelector(getListBudgetsReceivablesSelector)

  const [isLoading, setIsLoading] = useState(false)
  const [filters, setFilters] = useState({})

  const [page, setPage] = useState(0)
  const [orderBy, setOrderBy] = useState('')
  const [pageSize, setPageSize] = useState(100)
  const [menuType, setMenuType] = useState('default')
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(true)
  const [selectedItems, setSelectedItems] = useState([])
  const [selectedPayments, setSelectedPayments] = useState([])
  const [floatingMenuOpen, setFloatingMenuOpen] = useState(false)
  const [downloadSpreadsheetModalOpen, setDownloadSpreadsheetModalOpen] = useState(false)
  const [confirmationScreenPayments, setConfirmationScreenPayments] = useState([])

  const { showToast } = useToast()
  const { showDialog, closeDialog } = useDialog()

  const buttonColor = useMemo(() => {
    return isLoading ? Theme.Colors.Grayscale.ThirtyTwo : Theme.Colors.Primary.Base
  }, [isLoading])

  const isFilterDirty = useMemo(() => {
    return Object.values(filters).some(value => value)
  }, [filters])

  const receiptsColumns = useMemo(() => {
    return ReceiptsCsReferaColumns({ styles }).filter(
      column => !columnsToRemove.includes(column.field)
    )
  }, [ReceiptsCsReferaColumns])

  const formatResponseFilters = response => {
    const { filters: responseFilters } = response
    const [dueDateStart, dueDateEnd] = responseFilters?.firstDueDate
      ? responseFilters?.firstDueDate?.split(',')
      : ['', '']

    setFilters({
      ...responseFilters,
      dueDateStart: moment(dueDateStart).format('YYYY-MM-DD'),
      dueDateEnd: moment(dueDateEnd).format('YYYY-MM-DD'),
    })
  }

  const handleGetReceivables = useCallback(
    (filterParams = null) => {
      const formattedFilters = formatAsaasFilters(filterParams || filters)

      if (!isAdmin || !Object.values(formattedFilters).some(value => value)) return

      setIsLoading(true)
      const budgetReceivablesParams = { ...formattedFilters, pageSize, page: page + 1, orderBy }

      dispatch(getListBudgetReceivables(budgetReceivablesParams))
        .then(res => {
          setIsLoading(false)

          formatResponseFilters(res)
        })
        .catch(() => {
          setIsLoading(false)
          showToast({ type: 'error', message: 'Ocorreu um erro ao buscar os registros.' })
        })
      if (filterParams) setFilters(filterParams)
    },
    [isAdmin, receipts, filters, isFilterDirty, formatAsaasFilters, page, pageSize, orderBy]
  )

  const goBack = () => navigate(FINANCE_ROUTES.RECEIPTS)

  const renderFilters = useMemo(
    () => (
      <FiltersAsaas
        open={filterDrawerOpen}
        close={() => setFilterDrawerOpen(false)}
        applyFilters={handleGetReceivables}
        filters={filters}
        setFilters={setFilters}
      />
    ),
    [filterDrawerOpen, filters, handleGetReceivables, isLoading]
  )

  const handleOrderBy = useCallback(
    orderObj => {
      const order = orderObj[0]
      if (!order) {
        handleOrderBy('dueDate')
        return
      }
      const { field, sort } = order
      const decamelizedField = humps.decamelize(field)
      sort === 'desc' ? setOrderBy(`-${decamelizedField}`) : setOrderBy(decamelizedField)
    },
    [setOrderBy]
  )

  const handleCellClick = useCallback(
    params => {
      if (params.field === '__check__') return
      if (params.field === 'serviceOrderId') {
        window.open(
          `${ROUTES.SERVICE_ORDER}/${params.row.serviceOrderId}`,
          '_blank',
          'noopener,noreferrer'
        )
        return
      }
      const url = isAdmin
        ? `${FINANCE_ROUTES.RECEIPT_DETAILS}/${params.row.id}`
        : `${ROUTES.FINANCE_MANAGER}/detalhes-recebimento/${params.row.id}`

      window.open(url, '_blank', 'noopener,noreferrer')
    },
    [isAdmin]
  )

  const handleSelectedPayments = useCallback(() => {
    if (selectedItems?.length > 0) {
      const newArray = [...selectedPayments?.filter(item => selectedItems.includes(item.id))]

      receipts?.results?.map(
        item =>
          selectedItems.includes(item.id) &&
          !selectedPayments.filter(payment => item.id === payment.id)[0] &&
          newArray.push(item)
      )

      setSelectedPayments(newArray)
      setFloatingMenuOpen(true)
    } else {
      if (floatingMenuOpen) {
        setFloatingMenuOpen(false)
      }
      setSelectedPayments([])
    }
  }, [selectedItems, selectedPayments, receipts])

  const handleFloatingModalNavigation = useCallback(
    type => {
      if (type === 'block') {
        const nonBlockedItems = selectedPayments?.filter(item => !item.blockedFinanceTransfers)

        if (!nonBlockedItems?.length)
          return showToast({
            message: 'Nenhum registro selecionado está liberado para recebimento',
            type: 'error',
          })

        setConfirmationScreenPayments(nonBlockedItems)
        setMenuType('block')
      }

      if (type === 'release') {
        const blockedItems = selectedPayments?.filter(item => item.blockedFinanceTransfers)

        if (!blockedItems?.length)
          return showToast({
            message: 'Nenhum registro selecionado está bloqueado para recebimento',
            type: 'error',
          })

        setConfirmationScreenPayments(blockedItems)
        setMenuType('release')
      }

      if (type === 'download') {
        setDownloadSpreadsheetModalOpen(true)
      }

      return null
    },
    [selectedPayments, showToast]
  )

  const handleGoBackAndUpdate = useCallback(
    ({ update = false }) => {
      setMenuType('default')

      if (update) {
        handleGetReceivables()
        setSelectedItems([])
      }
    },
    [menuType, handleGetReceivables]
  )

  const exportASAASReceivables = async () => {
    setIsLoading(true)
    const formattedFilters = formatAsaasFilters(filters)
    const budgetReceivablesParams = { ...formattedFilters, pageSize, page: page + 1, orderBy }
    await dispatch(postExportASAASReceivables(budgetReceivablesParams))
      .then(() => {
        showToast({ type: 'success' })
        setIsLoading(false)
        handleGetReceivables()
      })
      .catch(err => {
        const errorMessage = formatErrorMessage(err)
        showToast({ type: 'error', message: errorMessage })
        setIsLoading(false)
      })
  }

  const handleExport = useCallback(() => {
    if (receipts?.results?.length) {
      showDialog({
        type: 'warning',
        subject: 'Você tem certeza que deseja executar esta ação?',
        onApprove: () => {
          exportASAASReceivables()
          closeDialog()
        },
        labelApprove: 'Sim',
        labelCancel: 'Não',
        onCancel: () => {
          closeDialog()
        },
      })
    } else {
      showToast({ type: 'error', message: 'Favor aplicar um filtro' })
    }
  }, [receipts?.results, exportASAASReceivables, closeDialog, showDialog, showToast])

  const renderConfirmationScreen = useMemo(() => {
    if (asaasConfirmationScreenTypes.includes(menuType)) {
      return (
        <ReceiptsConfirmation
          goBack={handleGoBackAndUpdate}
          items={confirmationScreenPayments}
          menuType={menuType}
        />
      )
    }
    return null
  }, [menuType, asaasConfirmationScreenTypes])

  const handleDownloadSpreadsheet = useCallback(
    async ({ spreadsheetType }) => {
      const formattedFilters = formatAsaasFilters(filters)
      formattedFilters.blockedFinanceTransfers = false

      try {
        setIsLoading(() => true)
        const headers = getToken()
        const params = { ...formattedFilters, template: spreadsheetType }
        const decamelizedParams = humps.decamelizeKeys(notEmptyPayload(params))
        const paramString = new URLSearchParams(decamelizedParams).toString()

        const response = await axios(
          `${API_URL}/finance/budget-receivables-spreadsheets/download-asaas/?${paramString}`,
          { ...headers, responseType: 'blob' }
        )

        const now = moment().format('YYYYMMDDHHmmss')
        const fileSufix =
          spreadsheetType === 'closing_spreadsheet_intermediary' ? 'imobiliaria' : 'refera'
        const fileName = `Fechamento_${fileSufix}_${now}.xlsx`

        const url = window.URL.createObjectURL(new Blob([response.data]))
        const downloadLink = document.createElement('a')

        downloadLink.href = url
        downloadLink.setAttribute('download', fileName)
        document.body.appendChild(downloadLink)
        downloadLink.click()

        setIsLoading(() => false)
        showToast({ type: 'success' })
        setDownloadSpreadsheetModalOpen(false)
      } catch (error) {
        setIsLoading(() => false)
        showToast({
          type: 'error',
          message: formatErrorMessage(error),
        })
      }
    },
    [filters]
  )

  useEffect(() => {
    if (user?.id && !isAdmin) navigate('/')
  }, [user, isAdmin])

  useEffect(() => {
    handleGetReceivables()
  }, [page, pageSize, orderBy])

  useEffect(() => {
    handleSelectedPayments()
  }, [selectedItems])

  return (
    <Grid className={styles.page}>
      {menuType === 'default' && (
        <>
          {renderFilters}
          <HeaderTitle title="Fechamento" backButtonAction={goBack}>
            <Grid className={styles.buttonsContainer}>
              <Button
                variant="secondary"
                startIcon={<DocumentUpload color={Theme.Colors.Primary.Base} />}
                onClick={handleExport}
                disabled={isLoading}
              >
                Exportar ASAAS
              </Button>
              <Button
                variant="ghost"
                startIcon={<FilterIcon color={buttonColor} />}
                onClick={() => setFilterDrawerOpen(true)}
                disabled={isLoading}
              >
                Filtros
              </Button>
            </Grid>
          </HeaderTitle>
          {isFilterDirty ? (
            <Grid className={styles.tableContainer}>
              <Grid className={styles.mainTable}>
                <Datagrid
                  loading={isLoading}
                  rows={receipts?.results?.length ? receipts?.results : []}
                  columns={receiptsColumns}
                  paginationMode="server"
                  onPageChange={setPage}
                  page={page}
                  onPageSizeChange={setPageSize}
                  pageSize={pageSize}
                  rowCount={receipts?.count}
                  disableSelectionOnClick
                  onSortModelChange={handleOrderBy}
                  onCellClick={handleCellClick}
                  checkboxSelection
                  onSelectionModelChange={setSelectedItems}
                  selectionModel={selectedItems}
                  keepNonExistentRowsSelected
                  sx={TABLE_SX}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid className={styles.emptyScreenWrapper}>
              <EmptyScreen
                Icon={SearchIcon}
                title="Favor aplicar um filtro"
                subtitle="Para ver registros nesta tela, abra a área de filtros e aplique critérios para sua pesquisa"
              />
            </Grid>
          )}
          {selectedItems?.length > 0 && (
            <AsaasFloatingMenu
              selectedItems={selectedItems}
              items={selectedPayments}
              handleButtonsActions={handleFloatingModalNavigation}
            />
          )}
          {downloadSpreadsheetModalOpen && (
            <AsaasDownloadSpreadsheet
              open={downloadSpreadsheetModalOpen}
              close={() => setDownloadSpreadsheetModalOpen(false)}
              onConfirm={handleDownloadSpreadsheet}
              isLoading={isLoading}
            />
          )}
        </>
      )}
      {renderConfirmationScreen}
    </Grid>
  )
}

export default AsaasExport
