import React, { useEffect, useMemo } from 'react'

import useStyles from './styles'
import { SideFilter } from '_/components/side-filter'

import { FormProvider, useForm } from 'react-hook-form'

import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { useToast } from '_/hooks/use-toast'

import { REQUIRED_FIELD_MESSAGE, strMaxLenMessage } from '_/utils/form-validations'

import { DatePickerInterval } from '_/components/date-picker-interval'
import { BasicInput, Select } from '_/components/inputs'

import { formatServiceOrderFilter } from '../../../manage-installments/utils/functions'

import moment from 'moment'

import classNames from 'classnames'

const ASAAS_STATUS_OPTIONS = [
  { label: '(Todos)', value: 'all' },
  { label: 'Não exportado', value: 'unexported' },
  { label: 'Pendente', value: 'pending' },
  { label: 'Erro', value: 'error' },
  { label: 'Exportado', value: 'success' },
]

const RECEIVABLE_STATUS_OPTIONS = [
  { label: '(Todos)', value: 'all' },
  { label: 'Pago', value: 'paid' },
  { label: 'Pendente', value: 'pending' },
]

const BLOCKED_SELECT_OPTIONS = [
  { label: '(Todos)', value: 'all' },
  { label: 'Sim', value: 'true' },
  { label: 'Não', value: 'false' },
]

const yupSchema = yup.object().shape({
  serviceOrderId: yup.string().nullable(),

  agency: yup
    .string()
    .max(255, strMaxLenMessage())
    .when('serviceOrderId', {
      is: serviceOrderId => !serviceOrderId, // When serviceOrderId is not filled
      then: schema => schema.required(REQUIRED_FIELD_MESSAGE), // agency is required
      otherwise: schema =>
        schema.when(['dueDateStart', 'dueDateEnd'], {
          is: (dueDateStart, dueDateEnd) => dueDateStart || dueDateEnd, // Se alguma data estiver preenchida
          then: innerSchema => innerSchema.required(REQUIRED_FIELD_MESSAGE), // agency is required
          otherwise: innerSchema => innerSchema.notRequired(), // is not required
        }),
    }),

  dueDateStart: yup
    .string()
    .test('valid date', 'Insira uma data válida', value => {
      if (value) return moment(value).isValid()
      return true
    })
    .when('serviceOrderId', {
      is: serviceOrderId => !serviceOrderId, // When serviceOrderId is not filled
      then: schema => schema.required(REQUIRED_FIELD_MESSAGE), // dueDateStart is required
      otherwise: schema =>
        schema.when(['agency', 'dueDateEnd'], {
          is: (agency, dueDateEnd) => agency || dueDateEnd, // if any of these fields are filled
          then: innerSchema => innerSchema.required(REQUIRED_FIELD_MESSAGE), // dueDateStart is required
          otherwise: innerSchema => innerSchema.notRequired(), // is not required
        }),
    }),

  dueDateEnd: yup
    .string()
    .test('valid date', 'Insira uma data válida', value => {
      if (value) return moment(value).isValid()
      return true
    })
    .test(
      'date greater than start',
      'A data final deve ser maior que a inicial',
      (value, context) => {
        const { dueDateStart } = context.parent
        if (dueDateStart && value) {
          return moment(value).isSameOrAfter(moment(dueDateStart))
        }
        return true
      }
    )
    .when('serviceOrderId', {
      is: serviceOrderId => !serviceOrderId, // When serviceOrderId is not filled
      then: schema => schema.required(REQUIRED_FIELD_MESSAGE), // dueDateEnd is required
      otherwise: schema =>
        schema.when(['agency', 'dueDateStart'], {
          is: (agency, dueDateStart) => agency || dueDateStart, // if any of these fields are filled
          then: innerSchema => innerSchema.required(REQUIRED_FIELD_MESSAGE), // dueDateEnd is required
          otherwise: innerSchema => innerSchema.notRequired(), // is not required
        }),
    }),
})

export const FiltersAsaas = ({ open, close, filters, setFilters, applyFilters }) => {
  const styles = useStyles()
  const { showToast } = useToast()

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(yupSchema),
  })

  const { watch, setValue, errors, register, reset, handleSubmit } = methods

  const isRequiredBasedOnServiceOrderField = useMemo(() => {
    const { dueDateStart, dueDateEnd, agency, serviceOrderId } = watch()

    if (!dueDateStart && !dueDateEnd && !agency && serviceOrderId?.trim()) return false
    return true
  }, [watch()])

  const handleServiceOrderFilter = serviceOrder => {
    const formattedObj = formatServiceOrderFilter(serviceOrder)

    if (formattedObj?.message) {
      showToast({ ...formattedObj })
      return null
    }

    return formattedObj?.formattedServiceOrder
  }

  const handleFilters = data => {
    const { serviceOrderId, dueDateStart, dueDateEnd } = data
    let serviceOrderIds = serviceOrderId

    if (serviceOrderId?.trim()) {
      serviceOrderIds = handleServiceOrderFilter(serviceOrderId)
      if (!serviceOrderIds) return
    }

    const firstDueDate =
      dueDateStart && dueDateEnd
        ? `${moment(dueDateStart).format('yyyy-MM-DD')},${moment(dueDateEnd).format('yyyy-MM-DD')}`
        : null

    const filterParams = {
      ...data,
      firstDueDate,
      serviceOrderId: serviceOrderIds,
    }

    applyFilters(filterParams)
    close()
  }

  const handleChange = (value, name) => setValue(name, value)

  const formatDefaultValues = () => {
    register('dueDateStart')
    register('dueDateEnd')
    setValue('dueDateStart', filters?.dueDateStart ?? null)
    setValue('dueDateEnd', filters?.dueDateEnd ?? null)
  }

  const resetFilters = () => {
    reset()
    setFilters(watch())
    formatDefaultValues()
  }

  useEffect(() => {
    formatDefaultValues()
  }, [filters])

  const mainFilters = useMemo(() => {
    return (
      <>
        <BasicInput
          placeholder="Nome"
          name="agency"
          label="Nome da intermediária"
          defaultValue={filters?.agency || ''}
          labelClasses={styles.inputLabel}
          style={{ margin: '16px 0 -16px' }}
          required={isRequiredBasedOnServiceOrderField}
        />

        <DatePickerInterval
          name="dueDate"
          label="1º vencimento"
          localState={watch()}
          handleChange={handleChange}
          disableFuture={false}
          blackLabel={false}
          firstError={errors?.dueDateStart?.message}
          secondError={errors?.dueDateEnd?.message}
          required={isRequiredBasedOnServiceOrderField}
        />

        <BasicInput
          name="serviceOrderId"
          label="ID do chamado"
          placeholder="12345"
          defaultValue={filters?.serviceOrderId || ''}
          labelClasses={styles.inputLabel}
          style={{ margin: '16px 0 -16px' }}
        />

        <div className={styles.selectContainer}>
          <Select
            label="Status da parcela"
            name="receivableStatus"
            defaultValue={filters?.receivableStatus || 'all'}
            labelClasses={classNames(styles.inputLabel, styles.selectLabel)}
            options={RECEIVABLE_STATUS_OPTIONS}
            getKey={item => item.value}
            getValue={item => item.value}
            getLabel={item => item.label}
          />
        </div>
        <div className={styles.selectContainer}>
          <Select
            label="Status ASAAS"
            name="asaasStatus"
            defaultValue={filters?.asaasStatus || 'unexported'}
            labelClasses={classNames(styles.inputLabel, styles.selectLabel)}
            options={ASAAS_STATUS_OPTIONS}
            getKey={item => item.value}
            getValue={item => item.value}
            getLabel={item => item.label}
          />
        </div>
        <div className={styles.selectContainer}>
          <Select
            label="Bloqueado"
            name="blocked"
            defaultValue={filters?.blocked || 'all'}
            labelClasses={classNames(styles.inputLabel, styles.selectLabel)}
            options={BLOCKED_SELECT_OPTIONS}
            getKey={item => item.value}
            getValue={item => item.value}
            getLabel={item => item.label}
          />
        </div>
      </>
    )
  }, [{ ...methods }])

  const moreFilters = useMemo(() => {
    return (
      <>
        <BasicInput
          name="classification"
          label="Classificação"
          placeholder="Classificação"
          labelClasses={styles.inputLabel}
          style={{ margin: '16px 0 -16px' }}
          defaultValue={filters?.classification || ''}
        />
        <BasicInput
          name="noClassification"
          label="Sem classificação"
          placeholder="Classificação"
          labelClasses={styles.inputLabel}
          style={{ margin: '16px 0 -16px' }}
          defaultValue={filters?.noClassification || ''}
        />
      </>
    )
  }, [{ ...methods }])

  useEffect(() => {
    formatDefaultValues()
  }, [])

  return (
    <FormProvider {...methods}>
      <SideFilter
        open={open}
        close={close}
        mainFilters={mainFilters}
        moreFilters={moreFilters}
        cleanFilters={resetFilters}
        applyFilters={handleSubmit(handleFilters)}
      />
    </FormProvider>
  )
}
